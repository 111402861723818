// src/App.tsx
import React, { useEffect } from 'react';
import AppRoutes from './routes';
import { useDispatch, useSelector } from 'react-redux';
import { refreshToken } from './store/slices/auth/authThunks';
import { AppDispatch, RootState } from './store';
import LoadingSpinner from './components/loading';
import { useMutation, useQuery } from '@apollo/client';
import client from './utils/api';
import { GET_GOOGLE_TOKENS } from './graphql/mutations/auth';
import { UPDATE_ACCOUNT_MUTATION } from './graphql/mutations/auth/updateAccout';

const getIsGoogleCallback = () => {
  return window.location.pathname.includes(
    '/auth/google/callback'
  );
}
type ScrapeParams = {
  email: string
  googleAccessToken: string
  googleRefreshToken: string
}
const requestScrape = ({email, googleAccessToken, googleRefreshToken}: ScrapeParams) => {
  const today = new Date();
  const ninetyDaysAgo = new Date(new Date().setDate(today.getDate() - 90));
  const data = {
    user_email: email,
    google_access_token: googleAccessToken,
    google_refresh_token: googleRefreshToken,
    last_sync_datetime: ninetyDaysAgo.toISOString()
  }

  const message = {
    Records: [{
      body: JSON.stringify(data)
    }]
  }

  const body = process.env.NODE_ENV !== 'development' ? message : data
  
  const urlForEnv = {
    development: "http://localhost:8001/dev/pull-user" ,
    staging: "https://x4zr5hqqhaug5h4vskv3wqd3si0kjfvq.lambda-url.us-west-1.on.aws/",
    production: "https://qwcp7qvbujlceje3q4zzdjufha0aaqjx.lambda-url.us-west-1.on.aws/"
  }
  const url = urlForEnv[process.env.NODE_ENV as keyof typeof urlForEnv]

  return fetch(url, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
}

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const token = useSelector((state: RootState) => state.auth.token);
  const loading = useSelector((state: RootState) => state.auth.loading);
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const {data: googleTokens} = useQuery(GET_GOOGLE_TOKENS, {client, variables: { id: currentUser?.id }, skip: !currentUser})
  const [updateAccount] = useMutation(UPDATE_ACCOUNT_MUTATION, {client, refetchQueries: [GET_GOOGLE_TOKENS]})

  useEffect(() => {
    // Only try to refresh if we have a refresh token cookie but no token in Redux
    // Don't refresh if we're in the Google callback flow
    if (!token && !getIsGoogleCallback()) {
      dispatch(refreshToken());
    }
  }, [dispatch, token]);
  // Perform one-off scrape only when the user first signs up to get them some products quickly
  useEffect(() =>  {
    if (!currentUser || !googleTokens) return
    (async () => {
      const {googleAccessToken, googleRefreshToken, emailPermissionGranted, firstScrapeStarted } = googleTokens.user
      if (!emailPermissionGranted || firstScrapeStarted || !googleAccessToken || !googleRefreshToken) return
      try{
        requestScrape({
          email: currentUser?.email,
          googleAccessToken,
          googleRefreshToken
        })
      } finally {
        updateAccount({ variables: { 
          firstScrapeStarted: true
        }})
      }
    })()
  }, [currentUser, googleTokens])

  if (loading && !getIsGoogleCallback()) {
    return <LoadingSpinner />;
  }

  return <AppRoutes />;
};

export default App;
