import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useProduct } from './hooks/useProduct';
import LoadingSpinner from '../loading';
import ContextMenu from './ProductDetailsContextMenu';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ShareProductModal from '../modals/ShareProductModal';
import moment from 'moment';
import { CategoryTag } from './CategoryTag';
import { GET_PURCHASED_PRODUCT } from '../../graphql/queries/products/getPurchasedProduct';
import { useQuery } from '@apollo/client';
import client from '../../utils/api';

const ProductView: React.FC = () => {
  const { collectionId: productId = '' } = useParams<{ collectionId: string }>();
  const {
    loading,
    productPurchaseDate,
    error,
    notes,
    setNotes,
    recommendation,
    handleRecommendation,
    handleSubmitNotes,
  } = useProduct(productId);

  const { data } = useQuery(GET_PURCHASED_PRODUCT,{
    client,
    variables: { id: productId },
    fetchPolicy: 'network-only',
    skip: !productId
  });

  const product = data?.purchasedProduct
  const formattedDate = moment(
    productPurchaseDate || product?.createdAt
  ).format('MMM Do, YYYY');
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [isProductShareModalOpen, setisProductShareModalOpen] = useState(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const pranetCollectionId = product?.collections?.edges[0]?.node?.id;
  const ownerId = product?.user?.id;
  const { user } = useSelector((state: RootState) => state.auth);
  const isOwner = user?.id === ownerId;

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="text-red-500">Error: {error}</div>;
  if (!product)
    return (
      <div className="text-red-500">No access or product data available</div>
    );

  const { name, brand, image, price, color, size } = product?.product;

  const handleEditNotes = () => {
    setIsEditingNotes(true);
  };

  const handleSaveNotes = () => {
    handleSubmitNotes();
    setIsEditingNotes(false);
  };

  const toggleContextMenu = () => {
    setIsContextMenuOpen(!isContextMenuOpen);
  };

  return (
    <div className="flex flex-col lg:flex-row max-w-6xl mx-auto min-h-screen p-4 lg:p-0">
      {/* Left div */}
      <div className="w-full lg:w-1/2 p-4 lg:p-6 flex flex-col">
        {/* Share icon div */}
        {isOwner && (
          <div className="self-end mb-4">
            <img
              src="/assets/cabinetShareIcon.svg"
              alt="share"
              className="w-5 h-5 cursor-pointer"
              onClick={() => setisProductShareModalOpen(true)}
            />
          </div>
        )}
        {/* Image and details div */}
        <div className="border border-gray-200 p-4 rounded-lg flex flex-col items-center">
          <h2 className="text-xl font-medium text-center mb-1">{name}</h2>
          <p className="text-gray-600 mb-2">{brand?.name || 'brand'}</p>
          <img
            src={image || '/assets/411.svg'}
            alt={name}
            className="w-48 h-64 object-center my-4"
          />
          <p className="text-lg font-semibold mb-4">${price}</p>
          <p className="text-gray-600 mb-2">
            Color: {color || ' --'} Size: {size || ' --'}
          </p>
          <div className="flex self-end justify-evenly gap-2 items-center">
            <div className="relative">
              <button
                onClick={toggleContextMenu}
                className="text-gray-500 hover:text-gray-700"
              >
                ...
              </button>
              <ContextMenu
                isOpen={isContextMenuOpen}
                onClose={() => setIsContextMenuOpen(false)}
                product={product}
                currentCollectionId={pranetCollectionId}
                isOwner={isOwner}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Right div */}
      <div className="w-full lg:w-1/2 p-4 lg:p-6 lg:pt-24 flex flex-col">
        <div className="mb-4 pt-6 lg:pt-12 flex flex-col items-center">
          <CategoryTag category={product.category} productId={product.id}/>
          <p className="text-sm mb-2 text-center">Date: {formattedDate}</p>
          <p className="text-sm mb-2 text-center">
            Vendor: {product?.vendor || ' --'}
          </p>
          <p className="text-sm mb-2 text-center">
            Order #:{product?.orderReference || ' --'}
          </p>
          <br></br>
          <p className="font-semibold mb-2">
            {product.user.firstName} {product.user.lastName}
          </p>
          <div className="flex flex-wrap items-center space-x-2 mb-3">
            <span className="text-sm w-full lg:w-auto mb-2 lg:mb-0">
              {isOwner ? `Would you recommend:` : `Recommended? `}
            </span>
            <div className="flex space-x-2">
              {['Yes', 'No', 'Maybe'].map((option) => (
                <button
                  key={option}
                  className={`px-3 py-1 text-xs rounded ${
                    recommendation.toLowerCase() === option.toLowerCase()
                      ? 'bg-gray-700 text-white'
                      : recommendation === ''
                        ? 'bg-gray-200 text-gray-700'
                        : 'bg-gray-100 text-gray-500'
                  } ${
                    recommendation === option ? 'font-bold' : ''
                  } transition-colors duration-200 ease-in-out ${isOwner ? 'cursor-pointer' : 'cursor-default'}`}
                  onClick={
                    isOwner ? () => handleRecommendation(option) : undefined
                  }
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="flex justify-between items-center mb-2">
            <p className="font-semibold text-sm">Notes</p>
            {!isEditingNotes && isOwner && (
              <button
                className="absolute top-0 right-0 text-gray-700 hover:text-gray-200"
                onClick={handleEditNotes}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
              </button>
            )}
          </div>
          <textarea
            className="w-full p-3 border border-gray-200 rounded-md bg-gray-50 text-sm mb-3 placeholder:italic"
            rows={4}
            value={notes}
            onChange={(e) => {
              setIsEditingNotes(true);
              setNotes(e.target.value);
            }}
            placeholder={
              isOwner
                ? 'Add your notes here...'
                : "The owner didn't add any notes yet!"
            }
            readOnly={!isOwner}
          />

          {isEditingNotes && (
            <button
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded text-sm hover:bg-gray-300 transition-colors self-end"
              onClick={handleSaveNotes}
            >
              Submit
            </button>
          )}
        </div>
      </div>
      <ShareProductModal
        isVisible={isProductShareModalOpen}
        onClose={() => setisProductShareModalOpen(false)}
        product={product}
      />
    </div>
  );
};

export default ProductView;
