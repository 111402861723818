import { useState, useRef } from 'react';
import { executeGoogleAuthMutation } from '../graphql/mutations/auth/googleAuthMutation';
import { executeGoogleGmailConnectMutation } from '../graphql/mutations/auth/googleGmailConnectMutation';
import { setAuthState } from '../store/slices/auth';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { AuthUser } from '../types/authTypes';

// Response from Google Auth endpoints
interface GoogleAuthResponse {
  token: string;
  user: {
    id: string;
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    emailPermissionGranted?: boolean;
    birthday?: string;
    zip?: string;
    gender?: string;
    phone?: string;
  };
  success?: boolean;
  emailPermissionGranted?: boolean;
}

interface AuthError {
  type: string;
  error: string;
}

interface AuthSuccess {
  type: string;
  authData: GoogleAuthResponse;
}

interface GmailConnectSuccess {
  type: string;
  data: GoogleAuthResponse;
}

const convertToAuthUser = (response: GoogleAuthResponse): AuthUser => ({
  id: response.user.id,
  username: response.user.username,
  email: response.user.email,
  firstName: response.user.firstName,
  lastName: response.user.lastName,
  birthday: response.user.birthday,
  zip: response.user.zip,
  gender: response.user.gender,
  phone: response.user.phone,
  emailPermissionGranted:
    response.emailPermissionGranted || response.user.emailPermissionGranted,
});

/**
 * Custom hook to handle authentication callbacks.
 * @returns {Object}
 */
export const useAuthCallback = () => {
  const [loading, setLoading] = useState(false);
  const [authError, setAuthError] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  // Use refs to track processed codes
  const processedAuthCode = useRef<string | null>(null);
  const processedGmailCode = useRef<string | null>(null);

  const clearStorageItems = () => {
    localStorage.removeItem('auth_success');
    localStorage.removeItem('auth_error');
    localStorage.removeItem('gmail_connect_success');
    localStorage.removeItem('gmail_connect_error');
  };

  /**
   * Handles the Google authentication process.
   * @param {string} code - The authentication code received from Google.
   * @returns {Promise<void>}
   */
  const handleGoogleAuth = async (code: string): Promise<void> => {
    // Prevent multiple submissions of the same code
    if (processedAuthCode.current === code) {
      console.warn('Preventing duplicate auth code submission');
      return;
    }
    processedAuthCode.current = code;

    clearStorageItems();

    try {
      const authData = await executeGoogleAuthMutation(code);

      if (!authData?.token) {
        const errorData: AuthError = {
          type: 'GOOGLE_SIGN_IN_ERROR',
          error: 'No token received from authentication',
        };
        localStorage.setItem('auth_error', JSON.stringify(errorData));
        setAuthError('No token received from authentication');
        return;
      }

      const user = convertToAuthUser(authData);

      // Set auth state and wait for it to complete
      await Promise.resolve(
        dispatch(
          setAuthState({
            isAuthenticated: true,
            user,
            token: authData.token,
          })
        )
      );

      const successData: AuthSuccess = {
        type: 'GOOGLE_SIGN_IN_SUCCESS',
        authData,
      };
      localStorage.setItem('auth_success', JSON.stringify(successData));

      // Close the window first
      window.close();

      // Then redirect the parent window
      if (window.opener) {
        window.opener.location.href = '/inbox';
      } else {
        window.location.href = '/inbox'
      }
    } catch (err) {
      // Clear the processed code on error to allow retry
      processedAuthCode.current = null;

      const errorMessage =
        err instanceof Error ? err.message : 'Authentication failed';
      const errorData: AuthError = {
        type: 'GOOGLE_SIGN_IN_ERROR',
        error: errorMessage,
      };
      localStorage.setItem('auth_error', JSON.stringify(errorData));
      setAuthError(errorMessage);
    }
  };

  /**
   * Handles the Gmail connection process.
   * @param {string} code - The authentication code received from Google.
   * @returns {Promise<void>}
   */
  const handleGmailConnect = async (code: string): Promise<void> => {
    // Prevent multiple submissions of the same code
    if (processedGmailCode.current === code) {
      console.warn('Preventing duplicate Gmail connect code submission');
      return;
    }
    processedGmailCode.current = code;

    clearStorageItems();

    try {
      const data = await executeGoogleGmailConnectMutation(code);

      if (!data?.success || !data?.user) {
        const errorData: AuthError = {
          type: 'GOOGLE_GMAIL_CONNECT_ERROR',
          error: 'Gmail connection was not successful',
        };
        localStorage.setItem('gmail_connect_error', JSON.stringify(errorData));
        setAuthError('Gmail connection was not successful');
        return;
      }

      const successData: GmailConnectSuccess = {
        type: 'GOOGLE_GMAIL_CONNECT_SUCCESS',
        data,
      };
      localStorage.setItem(
        'gmail_connect_success',
        JSON.stringify(successData)
      );

      const user = convertToAuthUser(data);

      // Set auth state and wait for it to complete
      await Promise.resolve(
        dispatch(
          setAuthState({
            isAuthenticated: true,
            user,
            token: data.token,
          })
        )
      );
    } catch (err) {
      // Clear the processed code on error to allow retry
      processedGmailCode.current = null;

      const errorMessage =
        err instanceof Error ? err.message : 'Gmail connection failed';
      const errorData: AuthError = {
        type: 'GOOGLE_GMAIL_CONNECT_ERROR',
        error: errorMessage,
      };
      localStorage.setItem('gmail_connect_error', JSON.stringify(errorData));
      setAuthError(errorMessage);
    } finally {
      window.close();
      window.location.href = '/inbox'
    }
  };

  return {
    loading,
    setLoading,
    authError,
    setAuthError,
    handleGoogleAuth,
    handleGmailConnect,
  };
};
