import { useState, useRef, useEffect } from "react";
import { getColorForName } from "../../utils/getColorForName";
import { useQuery, useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import client from "../../utils/api";
import { useDispatch } from "react-redux";
import { updateProductCategoryAction } from "../../store/slices/products/productActions";
import { GET_PURCHASED_PRODUCT } from "../../graphql/queries/products/getPurchasedProduct";
import { GET_CATEGORIES } from "../../graphql/queries/categories/getCategories";

interface Category {
    id: string;
    name: string;
    color?: string;
}

interface SearchCategoriesData {
    searchCategories: {
        edges: Array<{
            node: Category;
        }>;
    };
}

interface UpdateCategoryResponse {
    updatePurchasedProductCategory: {
        purchasedProduct: {
            id: string;
            category: Category;
        };
        errors: Array<{
            field: string;
            message: string;
        }>;
    };
}

const SEARCH_CATEGORIES = gql`
  query SearchCategories($search: String!) {
    searchCategories(search: $search) {
      edges {
        node {
          id
          name
          color
        }
      }
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation UpdatePurchasedProductCategory($id: ID!, $categoryId: ID!) {
    updatePurchasedProductCategory(
      id: $id
      input: {
        category: $categoryId
      }
    ) {
      purchasedProduct {
        id
        category {
          id
          name
        }
      }
    }
  }
`;

interface CategoryTagProps {
    productId: string;
    category?: { name: string, color?: string };
    onCategoryChange?: (categoryName: string) => void;
}

export const CategoryTag = ({ productId, category, onCategoryChange }: CategoryTagProps) => {
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const color = category?.color?.toLocaleLowerCase() || getColorForName(category?.name);
    const searchRef = useRef<HTMLDivElement>(null);
    const [updateCategory] = useMutation<UpdateCategoryResponse>(UPDATE_CATEGORY, {
        client,
        refetchQueries: [{query: GET_PURCHASED_PRODUCT, variables: { id: productId }}, GET_CATEGORIES],
        update: (cache, { data }) => {
            if (data?.updatePurchasedProductCategory?.purchasedProduct) {
                const { id, category } = data.updatePurchasedProductCategory.purchasedProduct;
                
                // Update the product's category in the cache
                cache.modify({
                    id: cache.identify({ __typename: 'PurchasedProduct', id }),
                    fields: {
                        category: () => category
                    }
                });

                // Remove the product from the old category's product list
                if (category?.name) {
                    cache.modify({
                        fields: {
                            purchasedProductsByCategory: (existing: any, { readField }) => {
                                if (!existing?.edges) return existing;
                                
                                return {
                                    ...existing,
                                    edges: existing.edges.filter((edge: any) => {
                                        const nodeId = readField('id', edge.node);
                                        return nodeId !== id;
                                    })
                                };
                            }
                        }
                    });
                }
            }
        },
    });

    const { data } = useQuery<SearchCategoriesData>(SEARCH_CATEGORIES, {
        client,
        variables: { search: searchTerm || "" },
        skip: !isSearchOpen
    });

    const suggestedCategories = data?.searchCategories?.edges?.map((edge) => edge.node) || [];

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
                setIsSearchOpen(false);
            }
        };

        const handleEscapeKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setIsSearchOpen(false);
                setSearchTerm("")
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keydown", handleEscapeKey);
        
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("keydown", handleEscapeKey);
        };
    }, []);

    const handleTagClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsSearchOpen(!isSearchOpen);
    };

    const dispatch = useDispatch();
    
    const handleCategorySelect = async (categoryId: string, categoryName: string) => {
        if (categoryName === category?.name) return setIsSearchOpen(false);
        try {
            const result = await updateCategory({
                variables: {
                    id: productId,
                    categoryId
                }
            });

            if (result.data?.updatePurchasedProductCategory.errors?.length) {
                console.error('Failed to update category:', result.data.updatePurchasedProductCategory.errors);
                return;
            }

            // Update Redux state
            dispatch(updateProductCategoryAction({
                productId,
                category: {
                    id: categoryId,
                    name: categoryName
                }
            }));

            if (onCategoryChange) {
                onCategoryChange(categoryName);
            }
        } catch (error) {
            console.error('Error updating category:', error);
        }

        setIsSearchOpen(false);
        setSearchTerm("");
    };

    return (
        <div className="flex flex-col justify-end items-end mt-auto relative" ref={searchRef}>
            <div 
                onClick={handleTagClick}
                className={`bg-${color}-100 px-2 py-1 mb-2 rounded-full border border-gray-400 text-sm flex items-center justify-center h-fit w-fit cursor-pointer hover:bg-${color}-200`}
            >
                <span>
                    {category?.name || "Add Category"}
                </span>
            </div>
            
            {isSearchOpen && (
                <div className="absolute bottom-full mb-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full p-2 border-b border-gray-300 rounded-t-lg focus:outline-none"
                        placeholder="Search categories..."
                        onClick={(e) => e.stopPropagation()}
                        autoFocus
                    />
                    <div className="max-h-64 overflow-y-auto shadow-[inset_0px_0px_3px_2px_rgba(0,_0,_0,_0.1)]">
                        {suggestedCategories.map((cat) => (
                            <div
                                key={cat.id}
                                className="p-2 hover:bg-gray-200 cursor-pointer flex items-center hover:bg-opacity-50"
                                onClick={e => {
                                    e.stopPropagation();
                                    handleCategorySelect(cat.id, cat.name);
                                }}
                            >
                                <div className={`bg-${cat.color?.toLocaleLowerCase() || getColorForName(cat.name)}-200 rounded-full border-gray-400 border h-5 w-5 mr-2`}></div>
                                <span>
                                {cat.name}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
