import { createAction } from '@reduxjs/toolkit';
import { Product } from './helpers'; // Make sure to import the Product interface

export const addCollection = createAction<string>('products/addCollection');

export const setCollectionProducts = createAction<{
  collectionName: string;
  products: Product[];
  append?: boolean;
}>('products/setCollectionProducts');

export const moveProductAction = createAction(
  'moveProductBetweenCollections',
  (oldCollectionId, newCollectionId, productId) => ({
    payload: { oldCollectionId, newCollectionId, productId },
  })
);

export const removeProductFromCollection = createAction<{
  collectionId: string;
  productId: string;
}>('products/removeProductFromCollection');

export const duplicateProductAction = createAction<{
  sourceCollectionId: string;
  targetCollectionId: string;
  product: any;
}>('products/duplicateProduct');

export const updatePurchasedProductNoteAction = createAction<{
  id: string;
  notes: string;
}>('products/updatePurchasedProductNote');

export const updateProductCategoryAction = createAction<{
  productId: string;
  category: {
    id: string;
    name: string;
  };
}>('products/updateProductCategory');
