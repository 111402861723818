import { gql } from '@apollo/client';

export const GET_PURCHASED_PRODUCTS_BY_CATEGORY = gql`
query GetPurchasedProductsByCategory($categoryName: String!, $first: Int, $after: String) {
    purchasedProductsByCategory(categoryName: $categoryName, first: $first, after: $after) {
      pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            category {
              id
              name
              color
            }
            id
            createdAt
            privacyStatus
            isStarred
            vendor
            orderReference
            metadata
            product {
              name
              description
              currency
              image
              url
              price
              brand {
                name
              }
              color
              size
            }
          }
        }
    }
  }
`;
