import { gql } from '@apollo/client';

export const GET_COLLECTION_PRODUCTS = gql`
  query getCollectionProducts($id: ID!, $first: Int, $after: String) {
    collection(id: $id) {
      name
      id
      purchasedProducts(first: $first, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            category {
              id
              name
              color
            }
            id
            createdAt
            privacyStatus
            isStarred
            vendor
            orderReference
            metadata
            product {
              name
              description
              currency
              image
              url
              price
              brand {
                name
              }
              color
              size
            }
          }
        }
      }
    }
  }
`;
