import { createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../../utils/api';
import { LoginData } from '../../../types/authTypes';
import {
  setLoading,
  setAuthState,
  setErrors,
  handleAuthSuccess,
  handleAuthFailure,
} from './authSlice';
import { LOGIN_MUTATION } from '../../../graphql/mutations/auth/loginMutation';
import { UPDATE_ACCOUNT_MUTATION } from '../../../graphql/mutations/auth/updateAccout';
import { RootState } from '../..';
import AuthService from '../../../services/authService';

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const auth = new AuthService(client);
      const result = await auth.refreshToken();

      if (result?.token && result.user) {
        thunkAPI.dispatch(
          handleAuthSuccess({
            token: result.token,
            user: result.user,
          })
        );
        thunkAPI.dispatch(setErrors(null));
      } else {
        thunkAPI.dispatch(handleAuthFailure(['Failed to refresh token']));
      }
      thunkAPI.dispatch(setLoading(false));
      return result;
    } catch (error: any) {
      thunkAPI.dispatch(handleAuthFailure([error.message]));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (loginData: LoginData, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await client.mutate({
        mutation: LOGIN_MUTATION,
        variables: loginData,
      });

      if (response.data.tokenAuth.success) {
        thunkAPI.dispatch(
          handleAuthSuccess({
            token: response.data.tokenAuth.token,
            user: {
              ...response.data.tokenAuth.payload,
              ...response.data.tokenAuth.user,
            },
          })
        );
        thunkAPI.dispatch(setErrors(null));
      } else {
        thunkAPI.dispatch(handleAuthFailure(response.data.tokenAuth.errors));
      }
      thunkAPI.dispatch(setLoading(false));
      return response.data.tokenAuth;
    } catch (error: any) {
      thunkAPI.dispatch(handleAuthFailure([error.message]));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateAccountThunk = createAsyncThunk(
  'auth/updateAccount',
  async (userData: any, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await client.mutate({
        mutation: UPDATE_ACCOUNT_MUTATION,
        variables: userData,
      });
      if (response.data.updateAccount.success) {
        const state = thunkAPI.getState() as RootState;
        thunkAPI.dispatch(
          setAuthState({
            isAuthenticated: true,
            user: {
              ...state.auth.user,
              ...response.data.updateAccount.user,
            },
            token: state.auth.token,
          })
        );
        thunkAPI.dispatch(setErrors(null));
      } else {
        thunkAPI.dispatch(
          handleAuthFailure(response.data.updateAccount.errors)
        );
      }
      thunkAPI.dispatch(setLoading(false));
      return response.data.updateAccount;
    } catch (error: any) {
      thunkAPI.dispatch(handleAuthFailure([error.message]));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
