import { createReducer } from '@reduxjs/toolkit';
import {
  addCollection,
  setCollectionProducts,
  moveProductAction,
  removeProductFromCollection,
  updatePurchasedProductNoteAction,
  updateProductCategoryAction,
} from './productActions';

interface ProductsState {
  [key: string]: any[];
}

const initialState: ProductsState = {};

const productsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addCollection, (state, action) => {
      if (!state[action.payload]) {
        state[action.payload] = [];
      }
    })
    .addCase(setCollectionProducts, (state, action) => {
      const { collectionName, products, append } = action.payload;
      if (append && state[collectionName]) {
        state[collectionName] = [...state[collectionName], ...products];
      } else {
        state[collectionName] = products;
      }
    })
    .addCase(moveProductAction, (state, action) => {
      const { oldCollectionId, newCollectionId, productId } = action.payload;

      const product = state[oldCollectionId].find(
        (product) => product.id === productId
      );

      if (state[newCollectionId]) {
        state[newCollectionId].push(product);
      } else {
        state[newCollectionId] = [product];
      }

      state[oldCollectionId] = state[oldCollectionId].filter(
        (product) => product.id !== productId
      );
    })
    .addCase(removeProductFromCollection, (state, action) => {
      const { collectionId, productId } = action.payload;

      if (state[collectionId]) {
        state[collectionId] = state[collectionId].filter(
          (product) => product.id !== productId
        );
      } else {
        console.error(`Collection ${collectionId} not found`);
      }
    })
    .addCase(updatePurchasedProductNoteAction, (state, action) => {
      const { id, notes } = action.payload;
      for (const collectionId in state) {
        const product = state[collectionId].find((p) => p.id === id);
        if (product) {
          product.notes = notes;
          break;
        }
      }
    })
    .addCase(updateProductCategoryAction, (state, action) => {
      const { productId, category } = action.payload;
      // Update category in all collections where the product exists
      for (const collectionId in state) {
        const product = state[collectionId].find((p) => p.id === productId);
        if (product) {
          product.category = category;
        }
      }
    });
});

export default productsReducer;
