import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { CategoryTag } from './CategoryTag';
import ProductActions from './ProductActions';

export interface Product {
  id: string;
  privacyStatus: string;
  createdAt: string;
  isStarred: boolean;
  name: string;
  image: string;
  description: string;
  currency: string;
  url: string;
  price: string;
  brand: string;
  metadata: string;
  category?: {
    name: string
  }
}

interface ProductItemProps {
  product: Product;
  currentCollectionId?: string;
}

const ProductItem: React.FC<ProductItemProps> = ({ product, currentCollectionId }) => {
  const navigate = useNavigate();
  const productMetadata = product?.metadata ? JSON.parse(product.metadata) : {};
  const formattedDate = moment(
    productMetadata.purchase_date || product.createdAt
  ).format('MMM Do, YYYY');

  const handleItemClick = () => {
    navigate(`/product/${product?.id}`);
  };

  return (
    <div
      className="shadow hover:shadow-lg transition-shadow duration-300 border rounded-lg flex min-[900px]:flex-col flex-wrap sm:max-w-lg lg:max-w-xl relative cursor-pointer bg-white "
      onClick={handleItemClick}
    > 
      <div className="w-full flex justify-center items-center h-60 p-2 sm:p-0 mb-4 sm:mb-0 min-[1150px]:mb-0">
        <img
          src={product.image || '/assets/411.svg'}
          alt={product.name}
          className="object-contain min-h-[60%] max-h-full max-w-48 min-[900px]:max-w-60 "
        />
      </div>
      <div className="w-full bg-gray-200 h-[0.5px]"></div>
      <div className="min-w-32 flex-1 flex flex-col p-4">
        <div className="flex justify-between items-start mb-2">
          <h3 className="font-bold mb-2 line-clamp-2">{product.name}</h3>
        </div>
        <p className="text-sm text-gray-600">
          {product.brand || 'Product Brand'}
        </p>
        <p className="text-gray-800 text-lg sm:text-base">
          ${product.price || 'Price'}
        </p>
        <p className="text-gray-500 text-xs mb-4">{formattedDate}</p>
        <div className="flex flex-col justify-end items-end mt-auto">
          <CategoryTag 
            productId={product.id}
            category={product.category} 
          />
          <ProductActions product={product} currentCollectionId={currentCollectionId} />
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
