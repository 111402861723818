import React, { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarOpen } from '../../store/slices/ui';
import { RootState } from '../../store';

interface SidebarItemComponentProps {
  item: any;
  link?: string;
  color?: string;
}

const SidebarItemComponent: React.FC<SidebarItemComponentProps> = ({
  item,
  link,
  color
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const sidebarOpen = useSelector((state: RootState) => state.ui.sidebarOpen);

  const handleItemClick = () => {
    if (item?.children && item?.children.length > 0) {
      setOpen(!open);
    }
    if (link) {
      navigate(link);
      // Close sidebar on mobile when navigating
      if (sidebarOpen) {
        dispatch(setSidebarOpen(false));
      }
    }
  };

  return (
    <div ref={ref}>
      <button
        onClick={handleItemClick}
        className={`flex items-center p-2 hover:bg-gray-200 rounded w-full text-left ${
          link && useLocation().pathname === link ? 'bg-gray-200' : ''
        }`}
      >
        {color? <div className={`h-7 w-7 mr-2 border border-gray-400 rounded-full bg-${color}-200`}></div>
        :<img
          src="/assets/base/folder.svg"
          className="w-4 h-4 flex-shrink-0 mr-2"
        />}
        <span className="flex-grow whitespace-nowrap overflow-hidden text-ellipsis min-w-0">
          {item?.name}
        </span>
        {item?.children && item?.children?.length > 0 && (
          <svg
            className={`w-4 h-4 transform flex-shrink-0 ml-2 ${!open ? 'rotate-0' : 'rotate-180'}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        )}
      </button>
      {open && item?.children && item?.children.length > 0 && (
        <div className="ml-6 mt-2 space-y-1">
          {item?.children?.map(
            (subItem: { id: React.Key | null | undefined }) => (
              <SidebarItemComponent link={`/lists/${subItem.id}`} key={subItem.id} item={subItem} />
            )
          )}
        </div>
      )}
    </div>
  );
};

export default SidebarItemComponent;
