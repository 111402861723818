import { gql } from '@apollo/client';

export const UPDATE_ACCOUNT_MUTATION = gql`
  mutation updateAccount(
    $zip: String
    $gender: String
    $firstName: String
    $lastName: String
    $birthday: String
    $username: String
    $phone: String
    $firstScrapeStarted: Boolean
    $firstScrapeComplete: Boolean
  ) {
    updateAccount(
      zip: $zip
      gender: $gender
      firstName: $firstName
      lastName: $lastName
      username: $username
      birthday: $birthday
      phone: $phone
      firstScrapeStarted: $firstScrapeStarted
      firstScrapeComplete: $firstScrapeComplete
    ) {
      success
      errors
      user {
        firstName
        lastName
        id
        birthday
        zip
        email
        gender
        phone
        username
      }
    }
  }
`;
