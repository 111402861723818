import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CabinetItemsList from './CabinetItemsList';
import ShareModal from '../modals/ShareModal';
import { createCollection } from '../../store/slices/collections/collectionThunks';
import { RootState } from '../../store';
import { GmailBadge } from './GmailBadge';

interface CabinetProps {}

const Cabinet: React.FC<CabinetProps> = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const cabinet = useSelector((state: RootState) =>
    state.collections.collections.find(
      (collection: any) => collection.type === 'CABINET'
    )
  );
  const [isModalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [newCollectionName, setNewCollectionName] = useState('');
  const [isAdding, setIsAdding] = useState(false);

  const handleShareClick = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleSaveClick = () => {
    if (newCollectionName.trim()) {
      dispatch(
        createCollection({ name: newCollectionName, parent: cabinet.id }) as any
      );
      setNewCollectionName('');
      setIsAdding(false);
    }
  };

  const handleAddClick = () => {
    setIsAdding(true);
  };

  const handleCancelClick = () => {
    setNewCollectionName('');
    setIsAdding(false);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between sm:mr-16 sm:px-24 px-4">
        <div className="flex items-center flex-col sm:flex-row justify-between sm:justify-start sm:space-x-8 py-4 sm:py-6 sm:mb-2 w-full sm:w-auto">
          <h2 className="text-2xl sm:text-xl lg:text-3xl truncate sm:max-w-[50%] sm:max-w-none">
            {user?.username}'s Franklie
          </h2>
          {/* BUTTONS */}
          <div className="flex items-center mt-2 sm:mt-0">
            <div className="flex items-center mr-2">
              <button
                onClick={handleShareClick}
                className="focus:outline-none m-2 flex-shrink-0"
              >
                <img
                  src="/assets/cabinetShareIcon.svg"
                  alt="share"
                  className="w-5 h-5"
                />
              </button>
              <GmailBadge user={user} />
              <button
                onClick={handleAddClick}
                className="focus:outline-none m-2 flex-shrink-0"
              >
                <img
                  src="/assets/cabinet-item-context-icons/icon.svg"
                  alt="add"
                  className="w-6 h-5"
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col sm:justify-between sm:mr-16 sm:px-24 px-4 mb-8">
        <p className="text-sm text-gray-500">Welcome! So glad you're here.</p>
        <p className="text-sm text-gray-500 mt-4">
          If you chose to sync your Gmail, check your{' '}
          <img
            src="/assets/unsortedMail.svg"
            alt="inbox"
            className="w-4 h-4 mx-2 inline"
          />
          Inbox in ~15 min for your last 90 days of buys. If you didn't sync, no
          worries. Forward receipts to{' '}
          <a href="mailto:myreceipts@franklie.ai" className="text-black">
            myreceipts@franklie.ai
          </a>
          , and they'll show up in ~15 min.
        </p>
        <p className="text-sm text-gray-500 mt-4">
          In the meantime, create Lists using the{' '}
          <img
            src="/assets/cabinet-item-context-icons/icon.svg"
            alt="add"
            className="w-4 h-4 mx-2 inline"
          />
          by your name. You can then file purchases from your Inbox into them.
        </p>
      </div>
      {cabinet?.children && (
        <div className="flex justify-center sm:pr-12">
          <CabinetItemsList
            isAdding={isAdding}
            newCollectionName={newCollectionName}
            setNewCollectionName={setNewCollectionName}
            handleSaveClick={handleSaveClick}
            handleCancelClick={handleCancelClick}
          />
        </div>
      )}
      <ShareModal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        collection={cabinet}
      />
    </div>
  );
};

export default Cabinet;
