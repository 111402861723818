import { AuthUser } from "../../types/authTypes";

type Props = {
    user: AuthUser | null
}

export const GmailBadge = ({user}: Props) => {
    if (!user?.email?.endsWith('@gmail.com')) return null;

    if (user?.emailPermissionGranted) {
      return (
        <a href="/my-info" className="flex items-center ml-2 bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full min-w-fit">
          <svg
            className="w-4 h-4 mr-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          Synced
        </a>
      );
    }

    return (
      <a href='/my-info' className="flex items-center ml-2 bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full min-w-fit">
        <svg
          className="w-4 h-4 mr-1"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
        Not Synced
      </a>
    );
  };