import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { loginUser, setAuthState } from '../../store/slices/auth';
import { handleGoogleAuth } from '../../utils/giveGooglePermission';

/**
 * The login form component.
 */
const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useSelector((state: RootState) => state.auth);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formErrors, setFormErrors] = useState<{
    email?: string;
    password?: string;
  }>({});

  /**
   * Handles the post message event.
   * @param event - The message event.
   */
  useEffect(() => {
    window.addEventListener('message', handlePostMessage);
    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, []);

  /**
   * Validates the form.
   * @returns True if the form is valid, false otherwise.
   */
  const validateForm = (): boolean => {
    const errors: { email?: string; password?: string } = {};
    if (!email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email is invalid';
    }
    if (!password) {
      errors.password = 'Password is required';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  /**
   * Handles the form submission.
   * @param event - The form event.
   */
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!validateForm()) return;

    try {
      const resultAction = await dispatch(
        loginUser({ email: email.toLowerCase(), password })
      );
      if (loginUser.fulfilled.match(resultAction)) {
        navigate('/inbox');
      } else {
        throw new Error('Login failed');
      }
    } catch (error) {
      console.error('Failed to login: ', error);
      setFormErrors({ ...formErrors, password: 'Invalid email or password' });
    }
  };

  /**
   * Handles the post message event.
   * @param event - The message event.
   */
  const handlePostMessage = async (event: MessageEvent) => {
    if (event.origin !== window.location.origin) return;

    if (event.data.type === 'AUTH_SUCCESS') {
      try {
        dispatch(
          setAuthState({
            isAuthenticated: true,
            user: event.data.authData.user,
            token: event.data.authData.token,
          })
        );
        navigate('/inbox');
      } catch (error) {
        console.error('Error processing authentication:', error);
        setFormErrors({
          ...formErrors,
          password: 'Authentication failed',
        });
      }
    } else if (event.data.type === 'AUTH_ERROR') {
      setFormErrors({
        ...formErrors,
        password: event.data.data.error || 'Authentication failed',
      });
    }
  };

  return (
    <div className="w-full max-w-md mx-auto p-4 sm:p-6 lg:p-8">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <input
            className={`w-full px-4 py-2 mb-1 border ${
              formErrors.email ? 'border-red-500' : 'border-gray-300'
            } rounded`}
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {formErrors.email && (
            <p className="text-red-500 text-xs italic">{formErrors.email}</p>
          )}
        </div>
        <div className="mb-4">
          <input
            className={`w-full px-4 py-2 mb-1 border ${
              formErrors.password ? 'border-red-500' : 'border-gray-300'
            } rounded`}
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {formErrors.password && (
            <p className="text-red-500 text-xs italic">{formErrors.password}</p>
          )}
        </div>
        <button
          type="submit"
          className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#91987B] hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          disabled={loading}
        >
          {loading ? 'Logging in...' : 'Log in'}
        </button>
      </form>
      <Link to="/signup">
        <div className="flex items-center justify-center mt-6">
          <span className="opacity-50">SIGN UP</span>
        </div>
      </Link>
      <div className="flex items-center justify-center my-4">
        <hr className="border-gray-300 w-1/4" />
        <span className="mx-4 text-gray-500">OR</span>
        <hr className="border-gray-300 w-1/4" />
      </div>
      <div className="flex justify-center">
        <button
          onClick={handleGoogleAuth}
          className="flex items-center justify-center w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <img
            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
            alt="Google logo"
            className="w-5 h-5 mr-2"
          />
          Sign in with Google
        </button>
      </div>
    </div>
  );
};

export default LoginForm;
