import SidebarItemComponent from './SidebarItem';
import { useQuery } from '@apollo/client';
import { GET_CATEGORIES } from '../../graphql/queries/categories/getCategories';
import client from '../../utils/api';
import { getColorForName } from '../../utils/getColorForName';

const CategoriesSection = ({
  isCategoriesCollapsed,
  toggleCategoriesCollapse,
}: {
  isCategoriesCollapsed: boolean;
  toggleCategoriesCollapse: () => void;
}) => {

  const {data} = useQuery(GET_CATEGORIES, { client  })
  const categories = data?.categoriesWithPurchases?.edges.map((e: any) => e.node)

  return (
    <div>
      <div className="relative flex items-center justify-between my-2 ml-2">
        <div
          className="flex items-center cursor-pointer"
          onClick={toggleCategoriesCollapse}
        >
          <div className="flex items-center">
            <span className='mr-2 text-2xl'>
                🏷️
            </span>
            <span className="block font-medium pl-2 text-lg">
                Categories
            </span>
            <svg
              className={`w-4 h-4 ml-2.5 transform ${isCategoriesCollapsed ? 'rotate-0' : 'rotate-180'}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </div>
      </div>
      {!isCategoriesCollapsed && categories?.length && (
        <>
            <nav className="space-y-1.5 px-6 ">
                {categories?.map(
                    (category: { id: React.Key | null | undefined, name: string, color?: string }) => (
                        <SidebarItemComponent 
                          link={`/category/${category.name}`} 
                          key={category.id} 
                          item={category}
                          color={category.color?.toLocaleLowerCase() || getColorForName(category.name)}
                        />
                    )
                )}
            </nav>
        </>
      )}
    </div>
  );
};

export default CategoriesSection;
