export const getColorForName = (nameString?: string): string => {
    const secondChar = (nameString && nameString[4] || '').toLowerCase();
    const colorMap: { [key: string]: string } = {
      a: 'amber',
      b: 'blue',
      c: 'cyan',
      d: 'emerald',
      e: 'fuchsia',
      f: 'green',
      g: 'indigo',
      h: 'lime',
      i: 'orange',
      j: 'pink',
      k: 'purple',
      l: 'red',
      m: 'rose',
      n: 'sky',
      o: 'teal',
      p: 'violet',
      q: 'yellow',
      r: 'amber',
      s: 'blue',
      t: 'cyan',
      u: 'emerald',
      v: 'fuchsia',
      w: 'green',
      x: 'indigo',
      y: 'lime',
      z: 'orange'
    };
    
    return colorMap[secondChar] || 'green'; // default color if no match
  };
