import React, { useEffect, useRef, useState } from 'react';
import ContextMenu from './SidePanelContextMenu';
import SidebarItemComponent from './SidebarItem';
import { getInitials } from '../../utils/getInitials';
import { useDispatch } from 'react-redux';
import { createCollection } from '../../store/slices/collections';

const CabninetSection = ({
  toggleCabinetCollapse,
  handleContextMenuOpen,
  isCabinetCollapsed,
  contextMenuOpen,
  setContextMenuOpen,
  cabinet,
  toggleCabinetNavigate,
}: {
  toggleCabinetCollapse: () => void;
  handleContextMenuOpen: () => void;
  isCabinetCollapsed: boolean;
  contextMenuOpen: boolean;
  setContextMenuOpen: (open: boolean) => void;
  cabinet: any;
  toggleCabinetNavigate: () => void;
}) => {
  const dispatch = useDispatch();
  const initials = getInitials();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState('');

  const handleSaveClick = () => {
    if (newCollectionName.trim()) {
      dispatch(
        createCollection({ name: newCollectionName, parent: cabinet.id }) as any
      );
      setNewCollectionName('');
      setIsAdding(false);
    }
  };

  useEffect(() => {
    if (isAdding && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAdding]);

  const handleAddClick = () => {
    setIsAdding(true);
  };

  const handleCancelClick = () => {
    setNewCollectionName('');
    setIsAdding(false);
  };

  return (
    <>
      <div className="relative flex items-center justify-between mb-1">
        <div className="flex items-center cursor-pointer">
          <div className="w-10 h-10 bg-gray-300 rounded-full flex items-center justify-center">
            {initials}
          </div>
          <div className="ml-2 flex items-center">
            <span
              className="block font-medium text-lg"
              onClick={toggleCabinetNavigate}
            >
              My Franklie
            </span>
            <div onClick={toggleCabinetCollapse}>
              <svg
                className={`w-4 h-4 ml-6 transform ${isCabinetCollapsed ? 'rotate-0' : 'rotate-180'}`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
          </div>
        </div>
        <button className="text-gray-500 " onClick={handleContextMenuOpen}>
          ...
        </button>
        <ContextMenu
          isOpen={contextMenuOpen}
          onClose={() => setContextMenuOpen(false)}
          handleAddClick={handleAddClick}
        />
      </div>
      {!isCabinetCollapsed && cabinet && cabinet.children.length > 0 && (
        <nav className="space-y-1.5 px-6 ">
          {cabinet.children.map(
            (child: { id: React.Key | null | undefined }) => (
              <SidebarItemComponent key={child.id} item={child} link={`/lists/${child.id}`} />
            )
          )}
        </nav>
      )}
      {isAdding && (
        <div className="flex justify-center items-center py-2">
          <div className="flex items-center space-x-2 w-full p-2 border rounded-lg shadow-sm">
            <input
              ref={inputRef}
              type="text"
              value={newCollectionName}
              onChange={(e) => setNewCollectionName(e.target.value)}
              className="border rounded px-2 py-1 flex-1 w-full"
              placeholder="New List"
            />
            <button
              onClick={handleSaveClick}
              className="focus:outline-none border  text-black px-2 py-1 rounded border-gray-400"
            >
              ✔
            </button>
            <button
              onClick={handleCancelClick}
              className="focus:outline-none text-black px-2 py-1 rounded border border-gray-400"
            >
              ✖
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CabninetSection;
