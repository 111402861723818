import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import ConfirmationModal from '../modals/ConfirmationModal';
import {
  deleteProductThunk,
  moveProductBetweenCollectionsThunk,
} from '../../store/slices/products/productThunks';

interface ProductActionsProps {
  product: any;
  currentCollectionId?: string;
}

const ProductActions = ({ product, currentCollectionId }: ProductActionsProps) => {
  const dispatch = useDispatch();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationHandler, setConfirmationHandler] = useState<() => void>(
    () => {}
  );
  const [isAddToListOpen, setIsAddToListOpen] = useState(false);

  const collections = useSelector(
    (state: RootState) =>
      state.collections.collections.find(
        (collection: any) => collection.type === 'CABINET'
      )?.children || []
  );
  const inboxCollection = useSelector((state: RootState) =>
    state.collections.collections.find(
      (collection: any) => collection.type === 'INBOX'
    )
  );
  const archiveCollection = useSelector((state: RootState) =>
    state.collections.collections.find(
      (collection: any) => collection.type === 'ARCHIVE'
    )
  );

  const handleToggleAddToList = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsAddToListOpen((prev) => !prev);
  };

  const handleSelectList = (e: React.MouseEvent, targetCollection: any) => {
    e.stopPropagation();
    handleConfirmMove(targetCollection);
  };

  const handleConfirmMove = async (targetCollection: any) => {
    const sourceCollectionId = currentCollectionId || inboxCollection?.id;
    if (!sourceCollectionId) {
      console.error('Source collection not found');
      return;
    }

    await dispatch(
      moveProductBetweenCollectionsThunk({
        productId: product.id,
        oldCollectionId: sourceCollectionId,
        newCollectionId: targetCollection.id,
      }) as any
    );

    setShowConfirmationModal(false);
    setIsAddToListOpen(false);
  };

  const handleDeleteItem = (e: React.MouseEvent) => {
    e.stopPropagation();
    setConfirmationMessage(
      `Are you sure you want to delete "${product.name}"?`
    );
    setConfirmationHandler(() => handleConfirmDelete);
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    const sourceCollectionId = currentCollectionId || inboxCollection?.id;
    if (!sourceCollectionId) {
      console.error('Source collection not found');
      return;
    }

    await dispatch(
      deleteProductThunk({
        productId: product.id,
        collectionId: sourceCollectionId,
      }) as any
    );

    setShowConfirmationModal(false);
    setIsAddToListOpen(false);
  };

  return (
    <div
      className="flex items-center gap-2"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="relative">
        <button
          onClick={handleToggleAddToList}
          className="px-2 py-1 text-sm border rounded-md hover:bg-gray-50 flex items-center gap-2"
        >
          Add to list
        </button>

        {isAddToListOpen && (
          <div className="absolute z-10 top-full mt-1 w-48 bg-white rounded-md shadow-lg">
            <ul className="py-1">
              {collections.map((list: any) => (
                <li
                  key={list.id}
                  onClick={(e) => handleSelectList(e, list)}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
                >
                  <img
                    src="/assets/archive-context-menu/folder.svg"
                    className="w-4 h-4 mr-2"
                    alt={list.name}
                  />
                  {list.name}
                </li>
              ))}
              <li
                onClick={(e) => handleSelectList(e, archiveCollection)}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
              >
                <img
                  src="/assets/archive-context-menu/archive.svg"
                  className="w-4 h-4 mr-2"
                  alt="Archive"
                />
                Archive
              </li>
              <li
                onClick={(e) =>
                  handleSelectList(e, { id: 'new', name: 'New list' })
                }
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center border-t"
              >
                <img
                  src="/assets/archive-context-menu/new-list.svg"
                  className="w-4 h-4 mr-2"
                  alt="New list"
                />
                Create New List
              </li>
            </ul>
          </div>
        )}
      </div>

      <button
        onClick={(e) => handleDeleteItem(e)}
        className="p-1 hover:bg-gray-100 rounded-md"
      >
        <img
          src="/assets/archive-context-menu/delete.svg"
          className="w-4 h-4"
          alt="Delete item"
        />
      </button>

      {showConfirmationModal && (
        <ConfirmationModal
          message={confirmationMessage}
          onConfirm={confirmationHandler}
          onClose={() => setShowConfirmationModal(false)}
        />
      )}
    </div>
  );
};

export default ProductActions;
