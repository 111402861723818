import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { fetchCollectionProducts } from '../../store/slices/products/productThunks';
import LoadingSpinner from '../loading';
import { GmailBadge } from '../cabinet-details-view/GmailBadge';
import { GmailConnectButton } from '../settings/myAccount/MyInfo';
import { useMutation, useQuery } from '@apollo/client';
import { GET_GOOGLE_TOKENS } from '../../graphql/mutations/auth';
import client from '../../utils/api';
import { UPDATE_ACCOUNT_MUTATION } from '../../graphql/mutations/auth/updateAccout';
import ProductGrid from '../products/ProductGrid';

const UnsortedMail: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [endCursor, setEndCursor] = useState<string | undefined>(undefined);
  const loadingRef = useRef<HTMLDivElement>(null);

  const currentUser = useSelector((state: RootState) => state.auth.user);
  const {data: googleTokens} = useQuery(GET_GOOGLE_TOKENS, {client, variables: { id: currentUser?.id }, skip: !currentUser})
  const firstScrapeComplete = googleTokens?.user?.firstScrapeComplete
  const emailPermissionGranted = googleTokens?.user?.emailPermissionGranted
  const [updateAccount] = useMutation(UPDATE_ACCOUNT_MUTATION, {client, refetchQueries: [GET_GOOGLE_TOKENS]})

  // Select the INBOX collection from the state
  const inboxCollection = useSelector((state: RootState) =>
    state.collections.collections.find(
      (collection) => collection.type === 'INBOX'
    )
  );


  const { user } = useSelector((state: RootState) => state.auth);

  // Select products associated with the INBOX collection
  const products = useSelector(
    (state: RootState) => state.products[inboxCollection?.id]
  );

  const shouldPoll = !firstScrapeComplete && emailPermissionGranted && inboxCollection 
  const polling = !firstScrapeComplete && emailPermissionGranted

  const loadMore = async () => {
    if ((shouldPoll) || (!isLoading && hasMore && inboxCollection)) {
      try {
        setIsLoading(true);
        const result = await dispatch(fetchCollectionProducts({
          collectionId: inboxCollection?.id,
          first: 8,
          after: endCursor
        }));
        
        const payload = result.payload as { pageInfo: { hasNextPage: boolean; endCursor: string } } | undefined;
        if (payload) {
          const { pageInfo } = payload;
          setHasMore(pageInfo.hasNextPage);
          setEndCursor(pageInfo.endCursor);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };
  // When users first connect their gmail, we trigger a scrape (elsewhere)
  // Here, we poll the backend for those who have just connected
  useEffect(() => {
    let interval = setInterval(() => {}, 100000)
    let timeout = setTimeout(() => {}, 100000)
    if (!firstScrapeComplete && emailPermissionGranted) {
      clearInterval(interval)
      // poll every 5 seconds
      clearTimeout(timeout)
      interval = setInterval(() => {
        loadMore()
      }, 5000)
      // quit after 60 seconds
      timeout = setTimeout(() => {
        updateAccount({ variables: { 
          firstScrapeComplete: true
        }})
        clearInterval(interval)
      }, 60000)
    }
    if (firstScrapeComplete && interval) {
      clearInterval(interval)
      clearTimeout(timeout)
    } 
    return () => clearInterval(interval)
  }, [firstScrapeComplete, emailPermissionGranted])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      },
      { threshold: 0.1 }
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => observer.disconnect();
  }, [loadMore]);

  return (
    <div className="px-4 sm:px-6 md:px-8 py-6 mx-auto flex flex-col max-w-[100rem]">
      <div className="flex items-center mb-4 sm:mb-6">
        <img
          src="/assets/unsortedMail.svg"
          className="h-8 w-8 sm:h-10 sm:w-10 md:h-12 md:w-12 py-1"
          alt="Unsorted Mail"
        />
        <h2 className="text-xl sm:text-2xl md:text-3xl pl-2">
          {inboxCollection?.name}
        </h2>
        <GmailBadge user={user} />
      </div>
      <div className="w-full">
          {!user?.emailPermissionGranted && user?.email?.endsWith('@gmail.com') &&
          <div className='col-span-full flex justify-center my-8 flex-col items-center'>
            <GmailConnectButton/>
            <div className='mt-2'>
              <p className="text-sm text-gray-500 mt-4">
                Or forward receipts to{' '}
                <a href="mailto:myreceipts@franklie.ai" className="text-black">
                  myreceipts@franklie.ai
                </a>
              </p>
            </div>
          </div>
          }
            <>
              {polling && (
                <div className='col-span-full text-center py-8 text-gray-500 mb-8'>
                  {products?.length ? 
                  <div>
                    🎉 We found some purchaces! 
                    <br />
                    Give us another minute to find more... 
                  </div> :
                  <div>
                    We're loading products now. 
                    <br />
                    Give us just a few more seconds...
                  </div>}
                  <br />
                  <LoadingSpinner />
                </div>
              )}
              <ProductGrid products={products || []} />
              {(!isLoading && !products?.length && !polling && inboxCollection) && (
                <div className="col-span-full text-center py-8 text-gray-500">
                  No products in {inboxCollection?.name}
                </div>
              )}
              {!polling && <div ref={loadingRef} className="col-span-full flex justify-center py-4 h-60">
                {(isLoading) && <LoadingSpinner />}
              </div>}
            </>
        
      </div>
    </div>
  );
};

export default UnsortedMail;
