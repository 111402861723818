import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState, AppDispatch } from '../../store';
import { fetchCollectionProducts } from '../../store/slices/products/productThunks';
// import CollectionItem from './CollectionsHeaderItem';
import ProductItem from '../products/ProductItem';
import CabinetItemContextMenu from '../cabinet-details-view/CabinetItemContextMenu';
import { findCollectionAndParentsById } from '../../utils/findCollectionAndParentsById';
import { updateCollectionPrivacyStatusThunk } from '../../store/slices/collections';
import LoadingSpinner from '../loading';

const Collection: React.FC = () => {
  const { collectionId = '' } = useParams<{ collectionId: string }>();
  const [, setIsModalOpen] = useState(false);
  const [, setActionJustPerformed] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const collections = useSelector(
    (state: RootState) => state.collections.collections
  );

  const { collection, parents } = findCollectionAndParentsById(
    collections,
    collectionId
  );

  const products = useSelector(
    (state: RootState) => state.products[collection?.id ?? '']
  );

  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      if (collection) {
        setIsLoading(true);
        await dispatch(fetchCollectionProducts({collectionId: collection.id}));
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [collection, dispatch, collectionId]);

  const handleContextMenuOpen = () => {
    setContextMenuOpen(!contextMenuOpen);
  };

  if (!collection) {
    return <div>list not found or you dont have permission to view</div>;
  }

  const handleConfirmHide = async (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    await dispatch(
      updateCollectionPrivacyStatusThunk({
        id: collection.id,
        privacyStatus: 'HIDDEN',
      }) as any
    );
  };

  const handleConfirmUnhide = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      updateCollectionPrivacyStatusThunk({
        id: collection.id,
        privacyStatus: 'PRIVATE',
      }) as any
    );
  };

  const breadcrumbTitle = [
    ...parents.map((parent: { name: string }) => parent.name),
    collection.name,
  ].join(' > ');

  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32 py-6 max-w-7xl mx-auto flex flex-col">
      {/* Header */}
      <div className="flex items-center mb-4 sm:mb-6 ">
        <img
          src="/assets/base/folder.svg"
          alt="Collection logo"
          className="h-8 w-8 sm:h-10 sm:w-10 md:h-12 md:w-12 py-1"
        />
        <h2 className="text-xl sm:text-2xl md:text-3xl pl-2">
          {breadcrumbTitle}
        </h2>
        <div className="relative ml-2 mx-2">
          <button className="text-gray-500" onClick={handleContextMenuOpen}>
            ...
          </button>
          {contextMenuOpen && (
            <CabinetItemContextMenu
              item={collection}
              onClose={() => setContextMenuOpen(false)}
              onActionPerformed={() => setActionJustPerformed(true)}
              setIsModalOpen={setIsModalOpen}
            />
          )}
        </div>
        {collection.privacyStatus === 'PRIVATE' ? (
          <img
            src="/assets/cabinet-item-context-icons/sharedWith.svg"
            className="w-5 h-5 mx-2 cursor-pointer"
            onClick={handleConfirmHide}
            title="Hide list"
          />
        ) : (
          <img
            src="/assets/locked.svg"
            className="w-5 h-5 mx-2 cursor-pointer"
            onClick={handleConfirmUnhide}
            title="Unhide list"
          />
        )}
      </div>

      {/* Collection Items */}
      {/* {collection.children && collection.children.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
          {collection.children.map((child: any) => (
            <CollectionItem key={child.id} collection={child} />
          ))}
        </div>
      )} */}

      {/* Products */}
      <div className="flex justify-center px sm:px-8 md:px-12 w-full">
        <div className="grid grid-cols-1 sm:grid-cols-1 w-5/6 lg:grid-cols-2 gap-y-4 gap-x-8 sm:gap-x-10 lg:gap-x-16">
          {isLoading ? (
            <div className="col-span-full flex justify-center">
              <LoadingSpinner />
            </div>
          ) : products && products.length > 0 ? (
            products.map((product) => (
              <ProductItem
                key={product.id}
                product={product}
                currentCollectionId={collection.id}
              />
            ))
          ) : (
            <div className="col-span-full text-center py-8 text-gray-500">
              No products in {collection.name}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Collection;
