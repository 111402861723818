import ProductItem, { Product } from './ProductItem'

function ProductGrid({products}:{products:Product[]}) {
    return (
        <div className='mt-10 grid grid-cols-1 min-[540px]:grid-cols-2 min-[1200px]:grid-cols-3  min-[1400px]:grid-cols-4 gap-5'>
            {products?.map((product) => (
                <ProductItem key={product.id} product={product} />
            ))}
        </div>
    )
}

export default ProductGrid
