import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { useMutation } from '@apollo/client';
import { REGISTER_MUTATION } from '../../graphql/mutations/auth/registerMutation';
import client from '../../utils/api';
import { handleAuthSuccess } from '../../store/slices/auth';
import { handleGoogleAuth } from '../../utils/giveGooglePermission';

const SignUpForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [user, setUser] = useState({
    email: '',
    password1: '',
    password2: '',
    firstName: '',
    lastName: '',
    username: '',
  });

  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const [registerUser, { loading }] = useMutation(REGISTER_MUTATION, { client })

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
    setFormErrors({ ...formErrors, [name]: '' });
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};

    if (!user.email) newErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(user.email))
      newErrors.email = 'Email is invalid';

    if (!user.password1) newErrors.password1 = 'Password is required';
    else if (user.password1.length < 8)
      newErrors.password1 = 'Password must be at least 8 characters';

    if (user.password1 !== user.password2)
      newErrors.password2 = 'Passwords do not match';

    if (!user.firstName) newErrors.firstName = 'First name is required';
    if (!user.lastName) newErrors.lastName = 'Last name is required';
    if (!user.username) newErrors.username = 'Username is required';
    if (user.username.includes(user.email)) newErrors.username = "Please pick a username that doesn't include your email";

    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const extractFieldErrors = (errorMessage: string): Record<string, string> => {
    const newErrors: Record<string, string> = {};

    try {
      // Extract the JSON string from the error message (after the second "Registration failed:")
      const jsonStringMatch = errorMessage.match(
        /Registration failed: Registration failed: (.*)/
      );
      if (jsonStringMatch && jsonStringMatch[1]) {
        // Parse the extracted string by replacing single quotes with double quotes
        const errorObject = JSON.parse(jsonStringMatch[1].replace(/'/g, '"'));

        // Loop through the error object and map the messages to their respective fields
        for (const field in errorObject) {
          if (errorObject.hasOwnProperty(field)) {
            const fieldErrorArray = errorObject[field];
            if (Array.isArray(fieldErrorArray) && fieldErrorArray.length > 0) {
              // Map the field error message to the corresponding field
              newErrors[field] = fieldErrorArray[0].message;
            }
          }
        }
      } else {
        // If no field-specific errors are found, set a general API error
        newErrors.apiError = errorMessage;
      }
    } catch (e) {
      console.error('Error parsing error message:', e);
      // newErrors.apiError = 'An unexpected error occurred. Please try again.';
    }

    return newErrors;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!validateForm()) return;

    try {
      const resultAction = await registerUser({variables: user});
      if (resultAction.data.register) {
        dispatch(handleAuthSuccess({ 
          user: resultAction.data.register.user, 
          token: resultAction.data.register.token 
        }))
        navigate('/override-to-gmail', { state: user });
      } else {
        console.error('Failed to register: ', resultAction.errors);
        if (resultAction.errors?.[0].message) {
          // Parse the single GraphQL error
          const errorMessage = resultAction.errors?.[0].message 
          const fieldErrors = extractFieldErrors(errorMessage); // Extract field-specific errors
          setFormErrors({
            ...formErrors,
            ...fieldErrors,
          });
        } else {
          setFormErrors({
            ...formErrors,
            apiError: 'Registration failed. Please try again.',
          });
        }
      }
    } catch (error) {
      // console.error('An unexpected error occurred:', error);
      setFormErrors({
        ...formErrors,
        // apiError: 'An unexpected error occurred. Please try again later.',
      });
    }
  };

  return (
    <div className="w-full max-w-md mx-auto p-4">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex gap-4">
          <div className="w-full">
            <input
              className={`w-full px-4 py-2 border rounded ${
                formErrors.firstName ? 'border-red-500' : 'border-gray-300'
              }`}
              type="text"
              placeholder="First name"
              value={user.firstName}
              onChange={handleChange}
              name="firstName"
            />
            {formErrors.firstName && (
              <p className="text-red-500 text-xs mt-1">
                {formErrors.firstName}
              </p>
            )}
          </div>
          <div className="w-full">
            <input
              className={`w-full px-4 py-2 border rounded ${
                formErrors.lastName ? 'border-red-500' : 'border-gray-300'
              }`}
              type="text"
              placeholder="Last name"
              value={user.lastName}
              onChange={handleChange}
              name="lastName"
            />
            {formErrors.lastName && (
              <p className="text-red-500 text-xs mt-1">{formErrors.lastName}</p>
            )}
          </div>
        </div>
        <div>
          <input
            className={`w-full px-4 py-2 border rounded ${
              formErrors.email ? 'border-red-500' : 'border-gray-300'
            }`}
            type="email"
            placeholder="Email"
            value={user.email}
            onChange={handleChange}
            name="email"
          />
          {formErrors.email && (
            <p className="text-red-500 text-xs mt-1">{formErrors.email}</p>
          )}
        </div>
        <div>
          <input
            className={`w-full px-4 py-2 border rounded ${
              formErrors.password1 ? 'border-red-500' : 'border-gray-300'
            }`}
            type="password"
            placeholder="Password"
            value={user.password1}
            onChange={handleChange}
            name="password1"
          />
          {formErrors.password1 && (
            <p className="text-red-500 text-xs mt-1">{formErrors.password1}</p>
          )}
        </div>
        <div>
          <input
            className={`w-full px-4 py-2 border rounded ${
              formErrors.password2 ? 'border-red-500' : 'border-gray-300'
            }`}
            type="password"
            placeholder="Re-enter password"
            value={user.password2}
            onChange={handleChange}
            name="password2"
          />
          {formErrors.password2 && (
            <p className="text-red-500 text-xs mt-1">{formErrors.password2}</p>
          )}
        </div>
        <div>
          <input
            className={`w-full px-4 py-2 border rounded ${
              formErrors.username ? 'border-red-500' : 'border-gray-300'
            }`}
            type="text"
            placeholder="Username"
            value={user.username}
            onChange={handleChange}
            name="username"
          />
          {formErrors.username && (
            <p className="text-red-500 text-xs mt-1">{formErrors.username}</p>
          )}
        </div>
        {formErrors.apiError && (
          <p className="text-red-500 text-center mt-2">{formErrors.apiError}</p>
        )}
        <button
          type="submit"
          className="w-full py-2 px-4 border border-transparent text-sm font-bold rounded-md text-white bg-[#91987B] hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50"
          disabled={loading}
        >
          {loading ? 'Signing up...' : 'SIGN UP'}
        </button>
      </form>
      <div className="flex items-center justify-center my-4">
        <hr className="border-gray-300 w-1/4" />
        <span className="mx-4 text-gray-500">OR</span>
        <hr className="border-gray-300 w-1/4" />
      </div>
      <div className="flex justify-center">
        <button
          onClick={handleGoogleAuth}
          className="flex items-center justify-center w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <img
            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
            alt="Google logo"
            className="w-5 h-5 mr-2"
          />
          Sign up with Google
        </button>
      </div>
    </div>
  );
};

export default SignUpForm;
