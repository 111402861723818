import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {  RootState } from '../../store';
import { resetState } from '../../store/rootActions';
import { SCREEENS_URL } from '../../assets/constants';
import { REVOKE_TOKEN_MUTATION } from '../../graphql/mutations/auth/revokeTokenMutation';
import client from '../../utils/api';

interface NavbarProps {}

const Navbar: React.FC<NavbarProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);
      await client.mutate({
        mutation: REVOKE_TOKEN_MUTATION
      });
      dispatch(resetState());
      setDropdownOpen(false);
      // Perform any other necessary cleanup here
    } catch (error) {
      console.error('Error during logout:', error);
      // Handle error (e.g., show a notification to the user)
    } finally {
      setIsLoggingOut(false);
      navigate('/login');
    }
  };

  const handleNavigateToSettings = () => {
    setDropdownOpen(false);
    navigate(SCREEENS_URL.SETTINGS.INDEX);
  };

  return (
    <header className="flex items-center justify-between p-4 bg-gray-100 border-b sticky top-0 z-40">
      <img
        src="/assets/411.svg"
        alt="411 logo"
        className="h-12 w-auto cursor-pointer"
        onClick={() => navigate(SCREEENS_URL.INBOX)}
      />
      <div className="flex items-center space-x-4">
        <div className="relative">
          <button
            className="p-2 rounded"
            onClick={toggleDropdown}
            disabled={isLoggingOut}
          >
            <div className="hamburger">
              <div className={`bar ${dropdownOpen ? 'open' : ''}`}></div>
              <div className={`bar ${dropdownOpen ? 'open' : ''}`}></div>
              <div className={`bar ${dropdownOpen ? 'open' : ''}`}></div>
            </div>
          </button>
          {dropdownOpen && (
            <div className="absolute right-0 mt-2 w-40 bg-white border rounded shadow-lg z-50">
              <div
                className="flex items-center p-3 hover:bg-gray-100 cursor-pointer"
                onClick={handleNavigateToSettings}
              >
                <img
                  src="/assets/settings.svg"
                  alt="Settings"
                  className="w-5 h-5 mr-4"
                />
                Settings
              </div>
              <div
                className="flex items-center p-3 hover:bg-gray-100 cursor-pointer"
                onClick={handleLogout}
              >
                <img
                  src="/assets/logout.svg"
                  alt="Logout"
                  className="w-5 h-5 mr-4"
                />
                {isLoggingOut ? 'Logging out...' : 'Logout'}
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Navbar;
