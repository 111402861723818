import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import CollectionItem from './CollectionsHeaderItem';
import { fetchCollectionProducts } from '../../store/slices/products/productThunks';
import ArchiveProductItemItem from './ArchiveProductItem';
import LoadingSpinner from '../loading';

const Archive: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(true);

  const archive = useSelector((state: RootState) =>
    state.collections?.collections.find(
      (collection) => collection.type === 'ARCHIVE'
    )
  );

  const archiveCollections = archive?.children;
  const archiveProducts = useSelector(
    (state: RootState) => state.products[archive?.id]
  );

  useEffect(() => {
    const fetchProducts = async () => {
      if (archive) {
        setIsLoading(true);
        await dispatch(fetchCollectionProducts({collectionId: archive.id}));
        setIsLoading(false);
      }
    };

    if (archive?.id) {
      fetchProducts();
    }
  }, [archive, dispatch, archive?.id]);

  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32 py-6 max-w-7xl mx-auto flex flex-col">
      {/* Header */}
      <div className="flex items-center mb-4 sm:mb-6">
        <img
          src="/assets/base/archive.svg"
          alt="Archive logo"
          className="h-8 w-8 sm:h-10 sm:w-10 md:h-12 md:w-12"
        />
        <h2 className="text-lg sm:text-xl md:text-2xl pl-2">Archive</h2>
      </div>

      {/* Archive Collections */}
      {archiveCollections && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
          {archiveCollections.map((child: any) => (
            <CollectionItem key={child.id} collection={child} />
          ))}
        </div>
      )}

      {/* Archive Products */}
      <div className="flex justify-center px-4 sm:px-8 md:px-12 w-full">
        <div className="grid grid-cols-1 sm:grid-cols-1 w-5/6 lg:grid-cols-2 gap-y-4 gap-x-8 sm:gap-x-10 lg:gap-x-16">
          {isLoading ? (
            <div className="col-span-full flex justify-center">
              <LoadingSpinner />
            </div>
          ) : archiveProducts && archiveProducts.length > 0 ? (
            archiveProducts.map((product) => (
              <ArchiveProductItemItem key={product.id} product={product} />
            ))
          ) : (
            <div className="col-span-full text-center py-8 text-gray-500">
              No archived products
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Archive;
