import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { ScrollToTop } from '../components/ScrollToTop';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import LoginPage from '../pages/auth/Login';
import SignUp from '../pages/auth/Signup';
import Home from '../pages/home';
import ConnectEmail from '../pages/auth/ConnectGmail';
import CreateAccount from '../pages/auth/CreateAccount';
import MainArea from '../components/home/MainArea';
import { SCREEENS_URL } from '../assets/constants';
import GoogleAuthCallback from '../components/auth/GoogleAuthCallback';
import NonGmailConnectGmail from '../pages/auth/NonGmailConnectGmail';

// Protected Route wrapper
const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const user = useSelector((state: RootState) => state.auth?.user);
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

// Auth Route wrapper (redirects to home if already authenticated)
const AuthRoute = ({ children }: { children: JSX.Element }) => {
  const user = useSelector((state: RootState) => state.auth?.user);

  if (user) {
    return <Navigate to="/inbox" replace />;
  }

  return children;
};

const AppRoutes = () => (
  <Router>
    <ScrollToTop />
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      >
        <Route
          index
          element={<Navigate to={`/${SCREEENS_URL.INBOX}`} replace />}
        />
        <Route path="category/:categoryName" element={<MainArea />} />
        <Route path=":collectionType/*" element={<MainArea />} />
        <Route path=":collectionType/:collectionId" element={<MainArea />} />
      </Route>

      <Route
        path="/login"
        element={
          <AuthRoute>
            <LoginPage />
          </AuthRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <AuthRoute>
            <SignUp />
          </AuthRoute>
        }
      />
      <Route path="/connect-to-gmail" element={<ConnectEmail />} />
      <Route path="/override-to-gmail" element={<NonGmailConnectGmail />} />
      <Route path="/create-account" element={<CreateAccount />} />
      <Route path="/auth/google/callback" element={<GoogleAuthCallback />} />
    </Routes>
  </Router>
);

export default AppRoutes;
