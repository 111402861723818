// graphql/queries/collections.ts
import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
query GetCategories {
  categoriesWithPurchases {
    edges {
      node {
        id
        name
        color
      }
    }
  }
}
`;
