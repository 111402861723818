import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_PURCHASED_PRODUCTS_BY_CATEGORY } from '../../graphql/queries/products/getPurchasedProductsByCategory';
import LoadingSpinner from '../loading';
import client from '../../utils/api';
import ProductGrid from './ProductGrid';

const CategoryProducts: React.FC = () => {
  const { categoryName } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [endCursor, setEndCursor] = useState<string | undefined>(undefined);
  const loadingRef = useRef<HTMLDivElement>(null);

  const { data, fetchMore, loading: initialLoading } = useQuery(GET_PURCHASED_PRODUCTS_BY_CATEGORY, {
    client,
    variables: {
      categoryName,
      first: 6,
      after: undefined
    },
    skip: !categoryName,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.purchasedProductsByCategory?.pageInfo) {
        setHasMore(data.purchasedProductsByCategory.pageInfo.hasNextPage);
        setEndCursor(data.purchasedProductsByCategory.pageInfo.endCursor);
      }
    }
  });

  const products = data?.purchasedProductsByCategory?.edges?.map((edge: any) => ({
    ...edge.node.product,
    brand: edge.node.product.brand?.name,
    id: edge.node.id, // Use the purchased product ID instead of product ID
    key: edge.node.id, // Use the unique purchased product ID as key
    category: edge.node.category,
    privacyStatus: edge?.node?.privacyStatus,
    createdAt: edge?.node?.createdAt,
    isStarred: edge?.node?.isStarred,
    name: edge?.node?.product?.name,
    image: edge?.node.product?.image,
    description: edge?.node?.product?.description,
    currency: edge?.node?.product?.currency,
    url: edge?.node?.product?.url,
    price: edge?.node?.product?.price,
    metadata: edge?.node?.metadata
  })) || [];

  const loadMore = useCallback(async () => {
    if (!isLoading && hasMore && categoryName) {
      try {
        setIsLoading(true);
        const result = await fetchMore({
          variables: {
            categoryName,
            first: 8,
            after: endCursor
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              purchasedProductsByCategory: {
                __typename: prev.purchasedProductsByCategory?.__typename,
                edges: [
                  ...prev.purchasedProductsByCategory?.edges,
                  ...fetchMoreResult.purchasedProductsByCategory?.edges
                ].filter((edge, index, self) => 
                  // Remove duplicates based on purchased product ID
                  index === self.findIndex((e) => e.node.id === edge.node.id)
                ),
                pageInfo: fetchMoreResult.purchasedProductsByCategory.pageInfo
              }
            };
          }
        });
        
        const pageInfo = result.data.purchasedProductsByCategory.pageInfo;
        setHasMore(pageInfo.hasNextPage);
        setEndCursor(pageInfo.endCursor);
      } finally {
        setIsLoading(false);
      }
    }
  }, [categoryName, endCursor, fetchMore, hasMore, isLoading]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      },
      { threshold: 0.1 }
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => observer.disconnect();
  }, [loadMore]);

  return (
    <div className="px-8 py-6 container mx-auto flex flex-col">
      <div className="flex items-center mb-4 sm:mb-6">
        <h2 className="text-xl sm:text-2xl md:text-3xl pl-2">
          {categoryName}
        </h2>
      </div>
      {(!isLoading && !initialLoading && !products.length) ? (
        <div className="text-center py-8 text-gray-500">
          No products in {categoryName}
        </div>
      ) : (
        <ProductGrid products={products} />
      )}
      <div ref={loadingRef} className="flex justify-center py-4 h-60">
        {(isLoading || initialLoading) && <LoadingSpinner />}
      </div>
    </div>
  );
};

export default CategoryProducts;
