import { gql } from '@apollo/client';

export const GET_PURCHASED_PRODUCT = gql`
  query purchasedProduct($id: ID!) {
    purchasedProduct(id: $id) {
      id
      category {
        id
        name
        color
      }
      notes
      recommendation
      privacyStatus
      isShared
      isStarred
      createdAt
      vendor
      orderReference
      metadata
      product {
        name
        brand {
          name
        }
        category {
          id
          name
        }
        image
        price
        color
        size
      }
      user {
        firstName
        lastName
        id
      }
      collections {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;
