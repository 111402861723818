import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { forwardRef } from 'react';

interface ConfirmationModalProps {
  message: string;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmationModal = forwardRef<HTMLDivElement, ConfirmationModalProps>(
  ({ message, onConfirm, onClose }, _ref) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return ReactDOM.createPortal(
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          ref={modalRef}
          className="bg-[#D7DBCE] p-6 rounded-lg shadow-xl text-center max-w-sm w-full opacity-95"
        >
          <p className="mb-6 text-lg font-medium text-black whitespace-pre-line">
            {message}
          </p>
          <div className="flex justify-center space-x-8">
            <button
              onClick={onConfirm}
              className="w-12 h-12 bg-white text-black rounded-full text-xl font-bold hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-150 ease-in-out"
            >
              Y
            </button>
            <button
              onClick={onClose}
              className="w-12 h-12 bg-white text-black rounded-full text-xl font-bold hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-150 ease-in-out"
            >
              N
            </button>
          </div>
        </div>
      </div>,
      document.body
    );
  }
);

export default ConfirmationModal;
